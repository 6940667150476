<template>
  <div>
    <div>
      <img :src="`${$staticUrl}/pd18.png`" />
    </div>
    <div class="tabs">
      <div class="title">
        <div @click="back" class="back">
          <img alt="返回" :src="`${$staticUrl}/back.png`" />
        </div>
        <span style="font-weight:bold;margin-left:20px">FUD500-1共轴无人直升机</span>
      </div>
      <div class="tab">
        <div class="tabItem" @click="setcTab(item.name)" v-for="item in tabList" :key="item.name" :class="[cTab==item.name ?'active':'']">{{item.name}}</div>
      </div>
    </div>
    <div class="content">
      <template v-if="cTab=='发动机简介'">
        <div class="text">
          <div class="title">FUD500-1共轴无人直升机</div>
          <div class="desc">
            FUD500-1型无人直升机,主要用于公共安全管理、反恐维稳、救援预警、森林消防等领域，处置突发事件时，可实现现场画面实时传送,为指挥者进行决策和判断提供依据。该机型平台还挂载催瓦斯投放器，喊话喇叭，强光照明、红外成像设备等，具备对突发事件的快速反应能力。带可见光摄像头可进行电路
            检查;带上农药、喷药装置可进行植保;带上摄像头可以进行航拍;带上灭火设备及灭火材料可进行消防;可作为运输工具。
          </div>
        </div>
        <div>
          <img :src="`${$staticUrl}/pd19.png`" />
        </div>
      </template>
      <template v-else>
        <div class="text">
          <div class="title">技术参数</div>
          <hr />
        </div>
        <div style="margin-top:20px">
          <detailInfo :dataList="dataList"></detailInfo>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import detailInfo from '@/components/Products/detailInfo'
export default{
  name:'',
  components:{detailInfo},
  data() {
    return {
      cTab:'发动机简介',
      dataList:[
        {
          label:'外形尺寸(长宽高)',
          value:'长度：1930mm、宽度：780mm、高度：1180mm、起落架高度380（不算在内）'
        },
        {
          label:'旋翼直径',
          value:'2640mm'
        },
        {
          label:'最大起飞重量',
          value:'130kg'
        },
        {
          label:'有效载付',
          value:'60-70Kg，留空时间30分钟'
        },
        {
          label:'巡航速度',
          value:'100-190公里/小时'
        },
        {
          label:'空机续航时间',
          value:'最长续航时间4-5小时，还可载7公斤荷重'
        },
        {
          label:'油耗',
          value:'11-12升/小时'
        },
        {
          label:'燃油混合比',
          value:'25:1 30:1'
        },
        {
          label:'备注',
          value:'根据不同用途，可配置各种专用设备，根据巡航时间要求挂载不同的油箱'
        },
      ],
      tabList:[
        {
          name:'发动机简介'
        },
        {
          name:'技术参数'
        }
      ]
    }
  },
  methods:{
    setcTab(val){
      this.cTab=val
    },
    back(){
      this.$router.back()
    }
  }
}
</script>
<style lang="less" scoped>
.tabs{
  width: 100%;
  height: 72px;
  background: #232323;
  padding:0 390px;
  line-height: 72px;
  display: flex;
  justify-content: space-between;
  color:#fff;
  position: relative;
  .title{
    display: flex;
    align-items: center;
    .back{
      width:24px;
      height: 24px;
    }
  }
  .tab{
    display: flex;
    justify-content: space-between;
    gap:20px;
    color:rgba(255,255,255,0.7);
    .tabItem{
      cursor: pointer;
    }
  }
  .active{
    color:#fff;
  }
}
.content{
  width:100%;
  padding:30px 390px;
}
.text{
  .title{
    color: #333333;
    font-weight: bold;
    font-size: 36px;
  }
  .desc{
    color: rgba(51,51,51,0.85);
    font-size: 24px;
    line-height: 40px;
    margin: 30px 0;
  }
}
</style>