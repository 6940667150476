<template>
  <div>
    <component :is="componentName"></component>
  </div>
</template>
<script>
import Page1_1 from './page1_1.vue'
import Page1_2 from './page1_2.vue'
import Page4_1 from './page4_1.vue'
import Page4_2 from './page4_2.vue'
import Page5_1 from './page5_1.vue'
import Page5_2 from './page5_2.vue'
import Page2_1 from './page2_1.vue'
import Page3_1 from './page3_1.vue'

export default {
  components: { Page1_1, Page1_2, Page4_1, Page4_2, Page5_1, Page5_2, Page2_1, Page3_1 },
  data () {
    return {
      componentName: 'Page1_3',
    }
  },
  created () { },
  computed: {},
  mounted () {
    let id = this.$route.params.id
    this.componentName = `Page${id}`
  },
  methods: {
  },
}
</script>
