<template>
    <div>
      <div>
        <img :src="`${$staticUrl}/pf1.png`" />
      </div>
      <!-- <div class="tabs">
        <div @click="back">
          <img alt="返回"
               :src="`${$staticUrl}/back.png`" />
        </div>
        <div style="margin-left:20px;font-weight:bold">
          航空发动机
        </div>
      </div> -->
      <div class="content">
        <div>
          <div class="title">
            <img :src="`${$staticUrl}/pf7.png`" />
          </div>
          <div class="imgs">
            <div @click="goto('2_1')"
                 class="imgs_item">
              <img :src="`${$staticUrl}/pf2.png`" />
            </div>
            <!-- <div @click="goto('1_2')"
                 class="imgs_item">
              <img :src="`${$staticUrl}/pd1-pdCover-2.png`" />
            </div> -->
          </div>
        </div>
  
      </div>
    </div>
  </template>
  <script>
  export default {
    name: '',
    data () {
      return {
  
      }
    },
    methods: {
      goto (name) {
        this.$router.push({
          path: "/pdetails2/" + name,
        });
      },
      back () {
        this.$router.back()
      }
    }
  }
  </script>
  <style lang="less" scoped>
  .content {
    width: 100%;
    padding: 30px 390px;
  }
  .tabs {
    width: 100%;
    height: 72px;
    background: #232323;
    color: #fff;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 390px;
    // .back{
    //   position: absolute;
    //   left:330px;
    //   top:50%;
    //   cursor: pointer;
    //   transform: translateY(-50%);
    // }
  }
  .title {
    width: 100%;
    margin-bottom: 10px;
    & p:first-child {
      font-size: 20px;
      font-family: Poppins-Bold, Poppins;
      font-weight: bold;
      color: #333333;
      margin-bottom: 8px;
    }
    & p:last-child {
      font-size: 16px;
      font-family: PingFang SC-Light, PingFang SC;
      font-weight: 300;
      color: #333333;
      margin-bottom: 18px;
    }
  }
  .imgs {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    .imgs_item {
      position: relative;
      cursor: pointer;
    }
  }
  .img_text {
    position: absolute;
    left: 40px;
    bottom: 40px;
    font-size: 24px;
    font-family: Montserrat-ExtraBold, Montserrat;
    font-weight: 800;
    color: #ffffff;
  }
  .img_more {
    width: 98px;
    height: 32px;
    border: 1px solid #fff;
    position: absolute;
    right: 40px;
    bottom: 40px;
    font-size: 14px;
    color: #ffffff;
    text-align: center;
    line-height: 32px;
  }
  </style>