<template>
  <div>
    <div>
      <img :src="`${$staticUrl}/pd1-detail-bg.png`" />
    </div>
    <div class="tabs">
      <div class="title">
        <div @click="back"
             class="back">
          <img alt="返回"
               :src="`${$staticUrl}/back.png`" />
        </div>
        <span style="font-weight:bold;margin-left:20px">EAGLE-212</span>
      </div>
      <div class="tab">
        <div class="tabItem"
             @click="setcTab(item.name)"
             v-for="item in tabList"
             :key="item.name"
             :class="[cTab==item.name ?'active':'']">{{item.name}}</div>
      </div>
    </div>
    <div class="content">
      <template v-if="cTab=='发动机简介'">
        <div class="text">
          <div class="title">EAGLE-212飞行汽车简介</div>
          <div class="desc">
            EAGLE-212飞行汽车采用模块化设计思路，整体平台分为飞行器模块、乘客舱（或载货舱）模块、地面行驶模块。通过海鸥团队自主发明创造的快速自动组合分离机构，各模块间可在数秒内实现快速组合、分离。
            EAGLE-212飞行汽车是一款智能多旋翼垂直起降飞行器，采用六旋翼双层桨动力形式，分布式动力布局，共12支桨叶。满电状态下，航时可达40分钟，航程60公里，具备抵御6～7级大风的抗干扰能力。

          </div>
        </div>

        <div>
          <img :src="`${$staticUrl}/pd1-detail-1.png`" />
          <br />
          <img :src="`${$staticUrl}/pd1-detail-4.png`" />
          <br />
          <img :src="`${$staticUrl}/pd1-detail-2.png`" />
          <br />
          <img :src="`${$staticUrl}/pd1-detail-3.png`" />
        </div>
      </template>
      <template v-else>
        <div class="text">
          <div class="title">性能参数</div>
          <hr />
        </div>
        <div style="margin-top:20px">
          <detailInfo :dataList="dataList"></detailInfo>
        </div>
        <div class="text">
          <div class="title">几何尺寸</div>
          <hr />
        </div>
        <div style="margin-top:20px">
          <detailInfo :dataList="dataList1"></detailInfo>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import detailInfo from '@/components/Products/detailInfo'
export default {
  name: '',
  components: { detailInfo },
  data () {
    return {
      cTab: '发动机简介',
      dataList: [
        {
          label: '最大巡航速度',
          value: '140公里/小时'
        },
        {
          label: '设计航程',
          value: '60公里'
        },
        {
          label: '实用升限',
          value: '3000米'
        },
        {
          label: '最大起飞重量',
          value: '650千克'
        },
        {
          label: '额定载荷 (载人)',
          value: '200千克'
        },
        {
          label: '额定载荷 (载货)',
          value: '300-400千克'
        },
        {
          label: '起飞方式',
          value: '垂直起降'
        },
      ],
      dataList1: [
        {
          label: '翼展',
          value: '5.7米'
        },
        {
          label: '全机长',
          value: '5.7米'
        },
        {
          label: '全机高',
          value: '2.3米'
        },
        {
          label: '动力系统',
          value: '-'
        },
        {
          label: '发动机',
          value: '直流无刷电机'
        },
        {
          label: '最大功率',
          value: '300千瓦'
        },
        {
          label: '电池类型',
          value: '锂电'
        },
      ],
      tabList: [
        {
          name: '飞行汽车简介'
        },
        {
          name: '技术参数'
        }
      ]
    }
  },
  methods: {
    setcTab (val) {
      this.cTab = val
    },
    back () {
      this.$router.back()
    }
  }
}
</script>
<style lang="less" scoped>
.tabs {
  width: 100%;
  height: 72px;
  background: #232323;
  padding: 0 390px;
  line-height: 72px;
  display: flex;
  justify-content: space-between;
  color: #fff;
  position: relative;
  .title {
    display: flex;
    align-items: center;
  }
  .tab {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    color: rgba(255, 255, 255, 0.7);
    .tabItem {
      cursor: pointer;
    }
  }
  .active {
    color: #fff;
  }
  .back {
    width: 24px;
    height: 24px;
    // position: absolute;
    // left:330px;
    // top:50%;
    // cursor: pointer;
    // transform: translateY(-50%);
  }
}
.content {
  width: 100%;
  padding: 30px 390px;
}
.text {
  .title {
    color: #333333;
    font-weight: bold;
    font-size: 36px;
  }
  .desc {
    color: rgba(51, 51, 51, 0.85);
    font-size: 24px;
    line-height: 40px;
    margin: 30px 0;
  }
}
</style>