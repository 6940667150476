<template>
  <div>
    <div>
      <img :src="`${$staticUrl}/pd16.png`" />
    </div>
    <div class="tabs">
      <div class="title">
        <div @click="back"
             class="back">
          <img alt="返回"
               :src="`${$staticUrl}/back.png`" />
        </div>
        <span style="font-weight:bold;margin-left:20px">FUD250-1 无人直升机</span>
      </div>
      <div class="tab">
        <div class="tabItem"
             @click="setcTab(item.name)"
             v-for="item in tabList"
             :key="item.name"
             :class="[cTab==item.name ?'active':'']">{{item.name}}</div>
      </div>
    </div>
    <div class="content">
      <template v-if="cTab=='发动机简介'">
        <div class="text">
          <div class="title">FUD250-1 无人直升机</div>
          <div class="desc">
            F250航空发动机，排量为250cc,电启动、双点火带减速器。该型号发动机是一款双缸缸水平对置、二冲程汽油活塞发动机，有液冷式、风冷式两种可选冷却模式。具有重量轻、爆发力强、适用性广等特点;可适用于无人固定翼飞机，直升机、共轴双桨无人机、多旋翼无人机、动力伞等飞行器，是一种理想的高可靠
            航空器材。该发动机配备有电子自动提前点火装置、采取电启动方式
          </div>
        </div>
        <div>
          <img :src="`${$staticUrl}/pd17.png`" />
        </div>
      </template>
      <template v-else>
        <div class="text">
          <div class="title">技术参数</div>
          <hr />
        </div>
        <div style="margin-top:20px">
          <detailInfo :dataList="dataList"></detailInfo>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import detailInfo from '@/components/Products/detailInfo'
export default {
  name: '',
  components: { detailInfo },
  data () {
    return {
      cTab: '发动机简介',
      dataList: [
        {
          label: '外形尺寸(长宽高)',
          value: '长度:2370mm、宽度：360mm、高度:820mm'
        },
        {
          label: '旋翼直径',
          value: '2425mm'
        },
        {
          label: '最大起飞重量',
          value: '55kg'
        },
        {
          label: '有效载付',
          value: '23kg(任务设备）'
        },
        {
          label: '巡航速度',
          value: '100公里/小时'
        },
        {
          label: '空机续航时间',
          value: '4小时'
        },
        {
          label: '油耗',
          value: '6升/小时'
        },
        {
          label: '备注',
          value: '根据不同用途，可配置各种专用设备，根据巡航时间要求挂载不同的油箱'
        },
      ],
      tabList: [
        {
          name: '发动机简介'
        },
        {
          name: '技术参数'
        }
      ]
    }
  },
  methods: {
    setcTab (val) {
      this.cTab = val
    },
    back () {
      this.$router.back()
    }
  }
}
</script>
<style lang="less" scoped>
.tabs {
  width: 100%;
  height: 72px;
  background: #232323;
  padding: 0 390px;
  line-height: 72px;
  display: flex;
  justify-content: space-between;
  color: #fff;
  position: relative;
  .title {
    display: flex;
    align-items: center;
  }
  .tab {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    color: rgba(255, 255, 255, 0.7);
    .tabItem {
      cursor: pointer;
    }
  }
  .active {
    color: #fff;
  }
  .back {
    width: 24px;
    height: 24px;
    // position: absolute;
    // left:330px;
    // top:50%;
    // cursor: pointer;
    // transform: translateY(-50%);
  }
}
.content {
  width: 100%;
  padding: 30px 390px;
}
.text {
  .title {
    color: #333333;
    font-weight: bold;
    font-size: 36px;
  }
  .desc {
    color: rgba(51, 51, 51, 0.85);
    font-size: 24px;
    line-height: 40px;
    margin: 30px 0;
  }
}
</style>