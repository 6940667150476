<template>
  <div>
    <div>
      <img :src="`${$staticUrl}/pd3-detail-bg.png`" />
    </div>
    <div class="tabs">
      <div class="title">
        <div @click="back"
             class="back">
          <img alt="返回"
               :src="`${$staticUrl}/back.png`" />
        </div>
        <span style="font-weight:bold;margin-left:20px">飞行监控指挥服务系统</span>
      </div>
      <div class="tab">
        <div class="tabItem"
             @click="setcTab(item.name)"
             v-for="item in tabList"
             :key="item.name"
             :class="[cTab==item.name ?'active':'']">{{item.name}}</div>
      </div>
    </div>
    <div class="content">
      <template v-if="cTab=='发动机简介'">
        <div class="text">
          <div class="title">EAGLE-212飞行汽车</div>
          <div class="desc">
            海鸥飞行汽车地面监控指挥服务系统是由海鸥科技有限公司独立研发的一款综合性的飞行指挥平台。
            飞行汽车监控指挥服务系统的建设，基于现有的成熟的通讯技术和正在发展的北斗定位技术，构建集飞行作业管理、气象信息管理、航空通告管理、载人飞行监视与告警等业务功能为一体的“监控指挥”系统，作为飞行服务站业务开展的支撑系统，用于保障通航飞行“看得见、听得到、飞得起、管得住”。
            地面监控指挥系统主要对空中飞行的多个飞行器使用空域、飞行路线、飞行速度、起飞降落参数、飞行器飞行参数等实施监控及指挥，涉及气象分析、空域及航线规划、航路评估、起降规范、空中防碰撞、飞行器空中传感、飞行器信息通讯、飞行器飞行状态监控、飞行指挥及控制等。
            地面监控指挥系统具备对低空飞行目标的监视及指挥，能够通过雷达、ADS-B获取本场的飞行目标，并通过数据链模块实现与空域内飞行目标的通讯，地面系统可以将飞行目标动态图形化显示。
            此外为更加精准、安全、高效地助力飞行汽车低空飞行运行，实施空管调度，监控指挥服务系统配置了地面指挥中心，负责预先飞行计划审批、飞行任务动态调度、气象和航行通告服务数据、地面保障资源管理、统计分析、数据回放和及其他扩展功能的任务处理。
          </div>
        </div>
        <div>
          <img :src="`${$staticUrl}/pd3-detail-1.png`" />
          <br />
          <img :src="`${$staticUrl}/pd3-detail-2.png`" />
          <br />
          <img :src="`${$staticUrl}/pd3-detail-3.png`" />
        </div>
      </template>
      <template v-else>
        <div class="text">
          <div class="title">性能参数</div>
          <hr />
        </div>
        <div style="margin-top:20px">
          <detailInfo :dataList="dataList"></detailInfo>
        </div>
        <div class="text">
          <div class="title">几何尺寸</div>
          <hr />
        </div>
        <div style="margin-top:20px">
          <detailInfo :dataList="dataList1"></detailInfo>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import detailInfo from '@/components/Products/detailInfo'
export default {
  name: '',
  components: { detailInfo },
  data () {
    return {
      cTab: '发动机简介',
      dataList: [
        {
          label: '最大巡航速度',
          value: '140公里/小时'
        },
        {
          label: '设计航程',
          value: '60公里'
        },
        {
          label: '实用升限',
          value: '3000米'
        },
        {
          label: '最大起飞重量',
          value: '650千克'
        },
        {
          label: '额定载荷 (载人)',
          value: '200千克'
        },
        {
          label: '额定载荷 (载货)',
          value: '300-400千克'
        },
        {
          label: '起飞方式',
          value: '垂直起降'
        },
      ],
      dataList1: [
        {
          label: '翼展',
          value: '5.7米'
        },
        {
          label: '全机长',
          value: '5.7米'
        },
        {
          label: '全机高',
          value: '2.3米'
        },
        {
          label: '动力系统',
          value: '-'
        },
        {
          label: '发动机',
          value: '直流无刷电机'
        },
        {
          label: '最大功率',
          value: '300千瓦'
        },
        {
          label: '电池类型',
          value: '锂电'
        },
      ],
      tabList: [
        {
          name: '飞行汽车简介'
        },
        // {
        //   name: '技术参数'
        // }
      ]
    }
  },
  methods: {
    setcTab (val) {
      this.cTab = val
    },
    back () {
      this.$router.back()
    }
  }
}
</script>
<style lang="less" scoped>
.tabs {
  width: 100%;
  height: 72px;
  background: #232323;
  padding: 0 390px;
  line-height: 72px;
  display: flex;
  justify-content: space-between;
  color: #fff;
  position: relative;
  .title {
    display: flex;
    align-items: center;
  }
  .tab {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    color: rgba(255, 255, 255, 0.7);
    .tabItem {
      cursor: pointer;
    }
  }
  .active {
    color: #fff;
  }
  .back {
    width: 24px;
    height: 24px;
    // position: absolute;
    // left:330px;
    // top:50%;
    // cursor: pointer;
    // transform: translateY(-50%);
  }
}
.content {
  width: 100%;
  padding: 30px 390px;
}
.text {
  .title {
    color: #333333;
    font-weight: bold;
    font-size: 36px;
  }
  .desc {
    color: rgba(51, 51, 51, 0.85);
    font-size: 24px;
    line-height: 40px;
    margin: 30px 0;
  }
}
</style>