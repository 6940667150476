<template>
  <div>
    <div>
      <img :src="`${$staticUrl}/pd-list-title.png`" />
    </div>
    <div class="pd_container">
      <div @click="setcItem(item.name)"
           class="pd_img_item"
           v-for="item in data1"
           :key="item.name">
        <img :src="item.src" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data () {
    return {
      data1: [
        {
          name: '1',
          src: `${this.$staticUrl}/pd2.png`,
        },
        {
          name: '2',
          src: `${this.$staticUrl}/pd3.png`
        },
        {
          name: '3',
          src: `${this.$staticUrl}/pd4.png`
        },
        {
          name: '4',
          src: `${this.$staticUrl}/pd5.png`
        },
        {
          name: '5',
          src: `${this.$staticUrl}/pd6.png`
        },
        // {
        //   name: '6',
        //   src: `${this.$staticUrl}/pd7.png`
        // },
      ],
    }
  },
  methods: {
    setcItem (name) {
      if (name == '6') {
        return
      }
      this.$router.push({
        path: "/pdetails/" + name,
      });
    }
  },
  mounted () {

  },
}
</script>
<style lang='less' scoped>
.pd_container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 15px 0;
  padding: 0 20px;
  justify-content: space-between;
  .pd_img_item {
    width: 49.5%;
    overflow: hidden;
    & img:hover {
      filter: brightness(1.2);
      transform: scale(1.1);
      transition: 1s;
    }
    cursor: pointer;
    & img {
      width: 100%;
    }
  }
}
</style>