<template>
  <div>
    <component :is="componentName"></component>
  </div>
</template>
<script>
import Page1 from './page1.vue'
import Page3 from './page3.vue'
import Page4 from './page4.vue'
import Page2 from './page2.vue'
import Page5 from './page5.vue'
export default {
  components: { Page1, Page4, Page2, Page5, Page3 },
  data () {
    return {
      componentName: 'Page1',
    }
  },
  created () { },
  computed: {},
  mounted () {
    let id = this.$route.params.id
    this.componentName = `Page${id}`
  },
  methods: {
  },
}
</script>
