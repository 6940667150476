<template>
  <div v-if="content.length < 0"
       style="width: 80%; height: 600px; margin: auto; padding: 40px 0">
    <a-skeleton active />
    <a-skeleton active />
    <a-skeleton active />
  </div>
  <div v-else
       class="content">
    <div class="breadcrumb">
      <router-link :to="{path: '/news'}"
                   tag="a">新闻中心</router-link>

      / &nbsp;&nbsp;<span>新闻详情</span>
    </div>
    <h1 class="title">
      {{title}}
    </h1>
    <p class="sub-title">
      <span>来源：{{source}}</span>
      <span>{{date}}</span>
    </p>
    <div v-html="content"></div>
  </div>
</template>
<script>
import { getNewsById as getNewsByIdReq } from '@/api/http/index'
export default {

  data () {
    return {
      content: '',
      title: '',
      source: '',
      date: ''
    }
  },
  beforeRouteEnter (to, from, next) {
    const id = to.params.id
    if (typeof id !== 'undefined') {
      next((vm) => {
        vm.getNewsById(id)
      })
    } else {
      if (!from.path.includes('/news')) {
        next('/news')
      } else {
        next()
      }

    }

  },
  created () { },
  computed: {},
  mounted () { },
  methods: {
    async getNewsById (id) {

      const res = await getNewsByIdReq({ id })
      if (res.code === 200) {

        const detail = res.data
        this.title = detail.title
        this.content = detail.content
        this.source = detail.originName
        this.date = detail.outputDate
      }
    }
  },
}
</script>
<style lang="less" scoped>
.breadcrumb {
  margin: 20px 0;
}
.content {
  width: 70%;
  margin: auto;
  :deep(img) {
    display: block;
    // width: unset;
    max-width: 100%;
    margin: auto;
  }
}
.title {
  color: #072d4b;
  font-size: 32px;
}
// p {
//   text-indent: 2em;
//   font-size: 18px;
//   line-height: 35px;
//   margin-bottom: 20px;
// }

// .foot {
//   font-size: 16px;
//   line-height: 30px;
//   > span {
//     display: block;
//   }
// }
.sub-title {
  color: #072d4b;
  font-size: 17px;
  text-indent: 0;
  > span {
    margin-right: 30px;
  }
}
</style>
