var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('img',{attrs:{"src":`${_vm.$staticUrl}/pf3.png`}})]),_c('div',{staticClass:"tabs"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"back",on:{"click":_vm.back}},[_c('img',{attrs:{"alt":"返回","src":`${_vm.$staticUrl}/back.png`}})]),_c('span',{staticStyle:{"font-weight":"bold","margin-left":"20px"}},[_vm._v("EAGLE-212")])]),_c('div',{staticClass:"tab"},_vm._l((_vm.tabList),function(item){return _c('div',{key:item.name,staticClass:"tabItem",class:[_vm.cTab == item.name ? 'active' : ''],on:{"click":function($event){return _vm.setcTab(item.name)}}},[_vm._v(_vm._s(item.name))])}),0)]),_c('div',{staticClass:"content"},[(_vm.cTab == '发动机简介')?[_vm._m(0),_c('div',{staticClass:"box1"},[_c('div',[_c('img',{attrs:{"src":`${_vm.$staticUrl}/pf5.png`}})]),_c('br'),_c('div',[_c('img',{attrs:{"src":`${_vm.$staticUrl}/pf6.png`}})])])]:[_vm._m(1),_c('div',{staticStyle:{"margin-top":"20px"}},[_c('detailInfo',{attrs:{"dataList":_vm.dataList}})],1),_vm._m(2),_c('div',{staticStyle:{"margin-top":"20px"}},[_c('detailInfo',{attrs:{"dataList":_vm.dataList2}})],1),_vm._m(3),_c('div',{staticStyle:{"margin-top":"20px"}},[_c('detailInfo',{attrs:{"dataList":_vm.dataList3}})],1),_vm._m(4),_c('div',{staticStyle:{"margin-top":"20px"}},[_c('detailInfo',{attrs:{"dataList":_vm.dataList4}})],1),_vm._m(5),_c('div',{staticStyle:{"margin-top":"20px"}},[_c('detailInfo',{attrs:{"dataList":_vm.dataList5}})],1),_vm._m(6),_c('div',{staticStyle:{"margin-top":"20px"}},[_c('detailInfo',{attrs:{"dataList":_vm.dataList6}})],1),_vm._m(7),_c('div',{staticStyle:{"margin-top":"20px"}},[_c('detailInfo',{attrs:{"dataList":_vm.dataList7}})],1),_vm._m(8),_c('div',{staticStyle:{"margin-top":"20px"}},[_c('detailInfo',{attrs:{"dataList":_vm.dataList8}})],1),_vm._m(9),_c('div',{staticStyle:{"margin-top":"20px"}},[_c('detailInfo',{attrs:{"dataList":_vm.dataList9}})],1)]],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('div',{staticClass:"title"},[_vm._v("EAGLE-300消防无人机")]),_c('div',{staticClass:"desc"},[_vm._v(" EAGLE-300消防无人机是一款大载重、智能多旋翼垂直起降电动消防无人机，采用六旋翼双层桨动力布局，具备5级抗风能力，具备失控保护、一键起飞及返航、水枪方向上下左右可调节等功能，同时配套高清图传系统、破甲弹等爆破工具，具有机动性强、操作简单快捷等特点，可在短时间内瞬间准确靠近高空着火点并将火势控制。 EAGLE-300消防无人机采用模块化设计，整体平台分为飞行器模块 、消防舱模块两大部分，并通过海鸥自主研发的快速自动分离组合机构，两个模块可在数秒内实现快速分离、组合，飞行器模块可在短时间内切换多个消防舱进行作业，大大节省消防舱的准备时间。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('div',{staticClass:"title"},[_vm._v("基础参数")]),_c('hr')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('div',{staticClass:"title"},[_vm._v("电机技术参数")]),_c('hr')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('div',{staticClass:"title"},[_vm._v("电调技术参数")]),_c('hr')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('div',{staticClass:"title"},[_vm._v("螺旋桨")]),_c('hr')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('div',{staticClass:"title"},[_vm._v("灭火罐")]),_c('hr')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('div',{staticClass:"title"},[_vm._v("电池参数")]),_c('hr')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('div',{staticClass:"title"},[_vm._v("遥控图传系统")]),_c('hr')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('div',{staticClass:"title"},[_vm._v("定位系统")]),_c('hr')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('div',{staticClass:"title"},[_vm._v("飞行特征")]),_c('hr')])
}]

export { render, staticRenderFns }