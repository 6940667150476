<template>
  <div>
    <div>
      <img :src="`${$staticUrl}/pd14.png`" />
    </div>
    <div class="tabs">
      <div class="title">
        <div @click="back" class="back">
          <img alt="返回" :src="`${$staticUrl}/back.png`" />
        </div>
        <span style="font-weight:bold;margin-left:20px"></span>F500型航空发动机
      </div>
      <div class="tab">
        <div class="tabItem" @click="setcTab(item.name)" v-for="item in tabList" :key="item.name" :class="[cTab==item.name ?'active':'']">{{item.name}}</div>
      </div>
    </div>
    <template v-if="cTab=='发动机简介'">
      <div class="content">
        <div class="text ab">
          <div class="title">F500型航空发动机</div>
          <div class="desc">
            F500型发动机是一款四缸水平对置、二冲程汽油活塞发动机，有液冷式、风冷式两种可选冷却模式。具有重量轻、爆发力强、适用性广等特点;可适用于无人直升机、共轴双桨无人机、多旋翼无人机、动力伞等飞行器，是一种理想的高可靠航空器材。该发动机采取电启动方式，并配备有电子自动提前点火装置。
          </div>
          <div class="tips">
            <div class="tip_title">采用双点火</div>
            <div class="tip_desc">发动机正常工作时，多一份安全保障</div>
          </div>
          <div class="tips">
            <div class="tip_title">采用减压起动</div>
            <div class="tip_desc">启动马达体积更小、重量更轻、耗能更低</div>
          </div>
          <div class="tips">
            <div class="tip_title">采用活塞联通机构进气</div>
            <div class="tip_desc">替代了传统阀片进气(由于进气频率快，传统阀片易折断)，从而提高安全保障</div>
          </div>
        </div>
        <div>
          <img :src="`${$staticUrl}/pd20.png`" />
        </div>
      </div>
    </template>
    <template v-else>
      <div class="content pa">
        <div class="text">
          <div class="title">技术参数</div>
          <hr />
        </div>
        <div style="margin-top:20px">
          <detailInfo :dataList="dataList"></detailInfo>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import detailInfo from '@/components/Products/detailInfo'
export default{
  name:'',
  components:{detailInfo},
  data() {
    return {
      cTab:'发动机简介',
      dataList:[
        {
          label:'排量',
          value:'500mL'
        },
        {
          label:'功率',
          value:'48 - 50hp（35 - 37kW）'
        },
        {
          label:'重量',
          value:'17公斤（含排气管、点火器）'
        },
        {
          label:'带负载',
          value:'额定转速6500±200 n/min、最高转速7000±200n/min、最低转速1500±200 n/min'
        },
        {
          label:'额定转速油耗量',
          value:'6 L/h'
        },
        {
          label:'使用汽油标号',
          value:'12L/h（92号/95号汽油）'
        },
        {
          label:'混合油配比',
          value:'1:40（建议1:35），磨合期为1:20'
        },
        {
          label:'外形尺寸 (长宽高)',
          value:'530mm x 400mm x 360mm'
        },
        {
          label:'产品可选辅件',
          value:'离合器、单轴减速器、双轴减速器、四轴减速器'
        },
      ],
      tabList:[
        {
          name:'发动机简介'
        },
        {
          name:'技术参数'
        }
      ]
    }
  },
  methods:{
    setcTab(val){
      this.cTab=val
    },
    back(){
      this.$router.back()
    }
  }
}
</script>
<style lang="less" scoped>
.tabs{
  width: 100%;
  height: 72px;
  background: #232323;
  padding:0 390px;
  line-height: 72px;
  display: flex;
  justify-content: space-between;
  color:#fff;
  position: relative;
  .title{
    display: flex;
    align-items: center;
    .back{
      width:24px;
      height: 24px;
    }
  }
  .tab{
    display: flex;
    justify-content: space-between;
    gap:20px;
    color:rgba(255,255,255,0.7);
    .tabItem{
      cursor: pointer;
    }
  }
  .active{
    color:#fff;
  }
}
.content{
  width:100%;
  position: relative;
}
.ab{
  width:630px;
  position: absolute;
  top:64px;
  left:390px;
}
.pa{
  padding:30px 390px;
}
.text{
  .title{
    color: #333333;
    font-weight: bold;
    font-size: 36px;
  }
  .desc{
    color: rgba(51,51,51,0.85);
    font-size: 24px;
    line-height: 40px;
    margin: 30px 0;
  }
  .tips{
    margin-bottom: 20px;
    .tip_title{
      color: #1D65E3;
      font-weight: bold;
      font-size: 28px;
      margin-bottom: 20px;
    }
    .tip_desc{
      color: rgba(51,51,51,0.85);
      font-size: 24px;
    }
  }
}
</style>