<template>
  <div>
    <div>
      <img :src="`${$staticUrl}/pf3.png`" />
    </div>
    <div class="tabs">
      <div class="title">
        <div @click="back"
             class="back">
          <img alt="返回"
               :src="`${$staticUrl}/back.png`" />
        </div>
        <span style="font-weight:bold;margin-left:20px">EAGLE-212</span>
      </div>
      <div class="tab">
        <div class="tabItem"
             @click="setcTab(item.name)"
             v-for="item in tabList"
             :key="item.name"
             :class="[cTab == item.name ? 'active' : '']">{{ item.name }}</div>
      </div>
    </div>
    <div class="content">
      <template v-if="cTab == '发动机简介'">
        <div class="text">
          <div class="title">EAGLE-300消防无人机</div>
          <div class="desc">
            EAGLE-300消防无人机是一款大载重、智能多旋翼垂直起降电动消防无人机，采用六旋翼双层桨动力布局，具备5级抗风能力，具备失控保护、一键起飞及返航、水枪方向上下左右可调节等功能，同时配套高清图传系统、破甲弹等爆破工具，具有机动性强、操作简单快捷等特点，可在短时间内瞬间准确靠近高空着火点并将火势控制。
            EAGLE-300消防无人机采用模块化设计，整体平台分为飞行器模块
            、消防舱模块两大部分，并通过海鸥自主研发的快速自动分离组合机构，两个模块可在数秒内实现快速分离、组合，飞行器模块可在短时间内切换多个消防舱进行作业，大大节省消防舱的准备时间。

          </div>
        </div>
        <div class="box1">
          <div>
            <img :src="`${$staticUrl}/pf5.png`" />
          </div>
          <br />
          <div>
            <img :src="`${$staticUrl}/pf6.png`" />
          </div>
        </div>

      </template>
      <template v-else>
        <div class="text">
          <div class="title">基础参数</div>
          <hr />
        </div>
        <div style="margin-top:20px">
          <detailInfo :dataList="dataList"></detailInfo>
        </div>

        <div class="text">
          <div class="title">电机技术参数</div>
          <hr />
        </div>
        <div style="margin-top:20px">
          <detailInfo :dataList="dataList2"></detailInfo>
        </div>

        <div class="text">
          <div class="title">电调技术参数</div>
          <hr />
        </div>
        <div style="margin-top:20px">
          <detailInfo :dataList="dataList3"></detailInfo>
        </div>

        <div class="text">
          <div class="title">螺旋桨</div>
          <hr />
        </div>
        <div style="margin-top:20px">
          <detailInfo :dataList="dataList4"></detailInfo>
        </div>

        <div class="text">
          <div class="title">灭火罐</div>
          <hr />
        </div>
        <div style="margin-top:20px">
          <detailInfo :dataList="dataList5"></detailInfo>
        </div>

        <div class="text">
          <div class="title">电池参数</div>
          <hr />
        </div>
        <div style="margin-top:20px">
          <detailInfo :dataList="dataList6"></detailInfo>
        </div>

        <div class="text">
          <div class="title">遥控图传系统</div>
          <hr />
        </div>
        <div style="margin-top:20px">
          <detailInfo :dataList="dataList7"></detailInfo>
        </div>

        <div class="text">
          <div class="title">定位系统</div>
          <hr />
        </div>
        <div style="margin-top:20px">
          <detailInfo :dataList="dataList8"></detailInfo>
        </div>

        <div class="text">
          <div class="title">飞行特征</div>
          <hr />
        </div>
        <div style="margin-top:20px">
          <detailInfo :dataList="dataList9"></detailInfo>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import detailInfo from '@/components/Products/detailInfo'
export default {
  name: '',
  components: { detailInfo },
  data () {
    return {
      cTab: '发动机简介',
      dataList: [
        {
          label: '轴距',
          value: '4000mm'
        },
        {
          label: '喷管长度',
          value: '3356mm'
        },
        {
          label: '外形尺寸',
          value: '6287mm x 3655mm x 1830mm(不含螺旋桨)'
        },
        {
          label: '机体材质',
          value: '碳纤维'
        },
        {
          label: '起飞重量',
          value: '500kg'
        },

      ],

      dataList2: [
        {
          label: '最大拉力',
          value: '99kg*12'
        },
        {
          label: 'KV值',
          value: '29rpm/V'
        },
        {
          label: '外形尺寸',
          value: '6287mm x 3655mm x 1830mm(不含螺旋桨)'
        },
        {
          label: '重量',
          value: '5.1kg*8'
        },
        {
          label: '电机尺寸',
          value: 'Φ 153.5*130.9'
        },
        {
          label: '最大电流',
          value: '280A'
        },
        {
          label: '轴承',
          value: 'NSK滚珠轴承（防水）'
        },


      ],

      dataList3: [
        {
          label: '型号',
          value: 'THUNDER 300A'
        },
        {
          label: '电池',
          value: '14-24S（60-100V DC）'
        },
        {
          label: 'PWM输入信号电平',
          value: '6287mm x 3655mm x 1830mm(不含螺旋桨)'
        },
        {
          label: '工作脉宽',
          value: '1100-1940µs'
        },
        {
          label: '最大允许电压',
          value: '200A'
        },
        {
          label: '最大允许电流(持续)',
          value: '280A'
        },
        {
          label: '最大允许峰值电流（3秒）',
          value: '300A'
        },


      ],
      dataList4: [
        {
          label: '材质',
          value: '碳纤维复合材料（纳米涂层）'
        },
        {
          label: '直径/螺距',
          value: '62寸'
        },
        {
          label: '重量',
          value: '27kg'
        },


      ],

      dataList5: [
        {
          label: '容积',
          value: '230L'
        },
        {
          label: '材质',
          value: '碳纤维复合材料'
        },
        {
          label: '重量',
          value: '27kg'
        },
        {
          label: '爆破压力',
          value: '5MPa'
        },
        {
          label: '罐装压力',
          value: '2.5MPa'
        },
        {
          label: '安装尺寸',
          value: '2150*2100*1225'
        },
        {
          label: '安装方式',
          value: '快挂式'
        },
        {
          label: '打开方式',
          value: '电子快开'
        },
      ],
      dataList6: [
        {
          label: '聚合物锂电池',
          value: '24S'
        },
        {
          label: '特性',
          value: '配套电池智能管理系统BMS'
        },

      ],
      dataList7: [
        {
          label: '遥控半径(含增程)',
          value: '10km'
        },
        {
          label: '图传距离',
          value: '10km'
        },
        {
          label: '数字图传',
          value: '1080P高清数字传输'
        },
      ],
      dataList8: [
        {
          label: '双GNSS模块',
          value: '实现精准的航向定位控制'
        },
        {
          label: '雷达模块(可选)',
          value: '实现扫描地形自动保持与地面火场的距离'
        },
        {
          label: 'RTK模块（可选）',
          value: '精度高达厘米级，定位精准，确保灭火飞行更加精准。'
        },
        {
          label: '超声波模块',
          value: '自动避障功能确保飞行安全'
        },
      ],

      dataList9: [
        {
          label: '工作频率',
          value: '2.400~2.483GHz',
        },
        {
          label: '等效全向辐射功率(EIRP)',
          value: '100mW/2.4GHz',
        },
        {
          label: '飞行时间',
          value: '25min (满载)45min (空载)',
        },
        {
          label: '工作电压',
          value: '100V',
        },
        {
          label: '飞行速度',
          value: '30米/秒',
        },
        {
          label: '工作环境温度',
          value: '-70°~40°',
        },
        {
          label: '抗风',
          value: 's5级',
        },
        {
          label: '夜航灯',
          value: '6个',
        },
        {
          label: '最大倾斜角度',
          value: '30°',
        },
        {
          label: '最大偏航速度',
          value: '150s',
        },
        {
          label: '飞行模式及功能',
          value:
            '姿态-增稳模式、姿态-定高模式、GPS-速度模式、GPS角度模式、自动返航模式、失控返航、低电压报警保护、地形跟随功能、急停功能、GPS速度切换功能、支持数传地面站飞行、支持数传远程调参、地面站具有图像识别、高度、距离、风速、温度等显示功能',
        },
      ],

      tabList: [
        {
          name: '发动机简介'
        },
        {
          name: '技术参数'
        }
      ]
    }
  },
  methods: {
    setcTab (val) {
      this.cTab = val
    },
    back () {
      this.$router.back()
    }
  }
}
</script>
<style lang="less" scoped>
.box1 {
  margin: 10px;
}

.tabs {
  width: 100%;
  height: 72px;
  background: #232323;
  padding: 0 390px;
  line-height: 72px;
  display: flex;
  justify-content: space-between;
  color: #fff;
  position: relative;

  .title {
    display: flex;
    align-items: center;
  }

  .tab {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    color: rgba(255, 255, 255, 0.7);

    .tabItem {
      cursor: pointer;
    }
  }

  .active {
    color: #fff;
  }

  .back {
    width: 24px;
    height: 24px;
    // position: absolute;
    // left:330px;
    // top:50%;
    // cursor: pointer;
    // transform: translateY(-50%);
  }
}

.content {
  width: 100%;
  padding: 30px 390px;
}

.text {
  .title {
    color: #333333;
    font-weight: bold;
    font-size: 36px;
  }

  .desc {
    color: rgba(51, 51, 51, 0.85);
    font-size: 24px;
    line-height: 40px;
    margin: 30px 0;
  }
}
</style>