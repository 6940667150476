<template>
  <div class="info_wrapper">
    <div class="rows"
         v-for="item in dataList"
         :key="item.label">
      <div class="row">
        <span class="label">{{item.label}}</span>
        <span class="value">{{item.value}}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    dataList: {
      type: Array
    }
  },
  data () {
    return {
    }
  }
}
</script>
<style lang="less" scoped>
.info_wrapper {
  width: 100%;
}
.rows:nth-child(odd) {
  background: #f4f8ff;
}
.rows:nth-child(even) {
  background: #fff;
}
.rows {
  width: 100%;
}
.row {
  min-height: 48px;
  display: flex;
  align-items: center;
  padding: 10px;
  color: rgba(51, 51, 51, 0.85);
  font-size: 20px;
  .label {
    flex: 1;
  }
  .value {
    flex: 2;
  }
}
</style>